import { Api } from "../api";

export const GET_LIST_LEADS = "GET_LIST_LEADS";
export const GET_DETAIL_LEADS = "GET_DETAIL_LEADS";
export const GET_LIST_ACTIVITY = "GET_LIST_ACTIVITY";
export const GET_AGE_LEAD = 'GET_AGE_LEAD';
export const GET_GENDER_LEAD = 'GET_GENDER_LEAD';
export const GET_LEAD_COMPARE_TODAY_BACKOFFICE = 'GET_LEAD_COMPARE_TODAY_BACKOFFICE';
export const GET_LEAD_COMPARE_TODAY_LINK = 'GET_LEAD_COMPARE_TODAY_LINK';
export const GET_LEAD_FOLLOW_UP = 'GET_LEAD_FOLLOW_UP';
export const GET_LEAD_MTD_BACKOFFICE = 'GET_LEAD_MTD_BACKOFFICE';
export const GET_LEAD_MTD_LINK = 'GET_LEAD_MTD_LINK';
export const GET_LEAD_SALES = 'GET_LEAD_SALES';
export const GET_LEAD_SOURCE = 'GET_LEAD_SOURCE';
export const GET_LEAD_TODAY_BACKOFFICE = 'GET_LEAD_TODAY_BACKOFFICE';
export const GET_LEAD_TODAY_LINK = 'GET_LEAD_TODAY_LINK';
export const GET_LEAD_COMPARE_MTD_BACKOFFICE = 'GET_LEAD_COMPARE_MTD_BACKOFFICE';
export const GET_LEAD_COMPARE_MTD_LINK = 'GET_LEAD_COMPARE_MTD_LINK';

export const getListLeads = ({ page = '', size = '', orgId = '', clubId = '', startDate = '', endDate = '', search = '', leadOwnerId = '', source = '', isCall = "", isExport = false }) => {
  return async (dispatch) => {
    try {
      if (search === null) search = "";
      const resp = await Api.get(
        `leads/master?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&search=${search}&salesId=${leadOwnerId}&source=${source}&isCall=${isCall}`
      );
      let data = resp.data.data ? resp.data.data : [];

      if (isExport) {
        return data;
      }

      dispatch({
        type: GET_LIST_LEADS,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getLeadsByClubBrand = (orgId = null, clubId = null) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `leads/master?orgId=${orgId}&clubId=${clubId}`
      );
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_LIST_LEADS,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getExportData = (orgId = null, clubId = null, startDate = null, endDate = null, search = null, leadOwnerId = null, source = '') => {
  return async () => {
    try {
      const resp = await Api.get(
        `leads/master?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&search=${search}&salesId=${leadOwnerId}&source=${source}`
      );
      let data = resp.data.data ? resp.data.data : [];

      return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  }
}

export const getDetailLeads = (id) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`leads/master/${id}`);
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_DETAIL_LEADS,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getListActivity = (page, size, orgId, leadId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `leads/follow-up-activity?page=${page}&size=${size}&orgId=${orgId}&leadId=${leadId}`
      );
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_LIST_ACTIVITY,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getReportLeads = ({ orgId, clubId, source, startDate, endDate, type, compareDay = false, compareMtd }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `report/leads?orgId=${orgId}&clubId=${clubId}&source=${source}&startDate=${startDate}&endDate=${endDate}`
      )

      let data = resp.data.data ? resp.data.data : null;

      let dispatchType = '';

      if (type === "today" && source === "backoffice") {
        if (compareDay) {
          dispatchType = GET_LEAD_COMPARE_TODAY_BACKOFFICE
        } else {
          dispatchType = GET_LEAD_TODAY_BACKOFFICE
        }
      }

      if (type === "today" && source === "link") {
        if (compareDay) {
          dispatchType = GET_LEAD_COMPARE_TODAY_LINK
        } else {
          dispatchType = GET_LEAD_TODAY_LINK
        }
      }

      if (type === "mtd" && source === "link") {
        if (compareMtd) {
          dispatchType = GET_LEAD_COMPARE_MTD_LINK
        } else {
          dispatchType = GET_LEAD_MTD_LINK
        }
      }

      if (type === "mtd" && source === "backoffice") {
        if (compareMtd) {
          dispatchType = GET_LEAD_COMPARE_MTD_BACKOFFICE
        } else {
          dispatchType = GET_LEAD_MTD_BACKOFFICE
        }
      }

      dispatch({
        type: dispatchType,
        data,
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}

export const getCountLeadFollowUp = (orgId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`report/lead-follow-up?orgId=${orgId}`)

      let data = resp.data.data ? resp.data.data : null;

      dispatch({
        type: GET_LEAD_FOLLOW_UP,
        data,
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}

export const getCountGenderLead = (orgId, clubId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`report/gender-lead?orgId=${orgId}&clubId=${clubId}`)

      let data = resp.data.data ? resp.data.data : null;

      dispatch({
        type: GET_GENDER_LEAD,
        data,
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}

export const getReportLeadAge = (orgId, clubId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`report/age-lead?orgId=${orgId}&clubId=${clubId}`)

      let data = resp.data.data ? resp.data.data : null;

      dispatch({
        type: GET_AGE_LEAD,
        data,
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}

export const getCountLeadSource = (page, size, orgId, clubId, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`report/lead-source?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}`)

      let data = resp.data.data ? resp.data.data : null;

      dispatch({
        type: GET_LEAD_SOURCE,
        data
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}

export const getCountLeadBySales = (page, size, orgId, clubId, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`report/lead-sales?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}`)

      let data = resp.data.data ? resp.data.data : null;

      dispatch({
        type: GET_LEAD_SALES,
        data
      })

    } catch (error) {
      console.log(error)
      return error?.response?.data
    }
  }
}